import { useContext } from "react";

import { ApiClientContext } from "@/components/ApiClientProvider";

export function useApiClient() {
  const context = useContext(ApiClientContext);

  if (!context) {
    throw new Error("ApiClientContext is not defined");
  }

  return context.apiClient;
}
