import { validateEnvVars } from "@revv/utils";

export const env = {
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? "",
  AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? "",
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? "",
  BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL ?? "",
};

validateEnvVars(env);
